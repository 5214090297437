<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Vulnerability by type
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Year
                    </th>
                    <th class="text-center">
                      # of Vulnerabilities
                    </th>
                    <th>DoS</th>
                    <th>Code Execution</th>
                    <th>Overflow</th>
                    <th>Memory Corruption</th>
                    <th>Sql Injection</th>
                    <th>XSS</th>
                    <th>Directory Traversal</th>
                    <th>Http Response Splitting</th>
                    <th>Bypass something</th>
                    <th>Gain Information</th>
                    <th>Gain Privileges</th>
                    <th>CSRF</th>
                    <th>File Inclusion</th>
                    <th># of exploits</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in vulnerabilities" :key="item.name">
                    <td>
                      <router-link :to="'/vulnerability-list/year-' + item.year">{{ item.year }}</router-link>
                    </td>
                    <td>{{ item.no_vulnerabilities }}</td>
                    <td>{{ item.dos }}</td>
                    <td>{{ item.code_execution }}</td>
                    <td>{{ item.overflow }}</td>
                    <td>{{ item.memory_consumption }}</td>
                    <td>{{ item.sql_injection }}</td>
                    <td>{{ item.xss }}</td>
                    <td>{{ item.directory_traversal }}</td>
                    <td>{{ item.http_response_splitting }}</td>
                    <td>{{ item.bypass_something }}</td>
                    <td>{{ item.gain_information }}</td>
                    <td>{{ item.gain_privileges }}</td>
                    <td>{{ item.csrf }}</td>
                    <td>{{ item.file_inclusion }}</td>
                    <td>{{ item.exploits }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      vulnerabilities: [],
      year: 2021,
      month: 1,
      loading: true
    }
  },
  computed: {},
  created() {
    this.getRecords()
    const dat = new Date()
    this.year = dat.getFullYear()
    this.month = dat.getMonth()
  },
  methods: {
    getRecords() {
      axios
        .get("cve_vulnerability_by_year_and_type")
        .then(response => {
          this.$log("products list ", response)
          this.vulnerabilities = response.data
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
td:first-child,
th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td {
  text-align: right !important;
}
td,
th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
th {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
